<template>
  <div v-loading="loading">
    <el-card shadow="never">
      <div slot="header">
        <span class="iconfont icon-Language">&nbsp;语言管理</span>
      </div>
      <!-- <div class="card_header_flex">
        <el-button type="primary" size="small" @click="dialogAddLanguage=true">新增语言</el-button>
      </div>-->
      <el-table :data="list" size="mini">
        <el-table-column prop="encoding" label="语言编码"></el-table-column>
        <el-table-column prop="sort" label="排序">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sort" size="mini" @change="onChangeSort(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="logo" label="语言LOGO">
          <template slot-scope="scope">
            <el-image
              :src="fileUrl+scope.row.logo"
              style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);"
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="语言名称"></el-table-column>
        <el-table-column prop="appPackage" label="APP语言包">
          <template slot-scope="scope">
            <el-input v-model="scope.row.appPackage" type="textarea" :rows="4" disabled></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="启用状态" align="center">
          <template slot-scope="scope">
            <el-switch
              v-if="scope.row.preset !='none'"
              v-model="scope.row.state"
              active-value="on"
              inactive-value="off"
              @change="onSwitchState(scope.row)"
            ></el-switch>
            <el-tag v-else type="success" size="mini" effect="dark">默认启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="openEditLanguage(scope.row)"
              v-if="scope.row.preset !='none'"
            >编辑</el-button>
            <el-button type="text" size="small" @click="defaultLanguage(scope.row)">恢复默认</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加语言 -->
    <el-dialog
      :visible.sync="dialogAddLanguage"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogAddLanguage"
    >
      <span slot="title">新增语言</span>
      <el-form
        :model="form"
        ref="form"
        label-width="120px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="语言编码"
          prop="encoding"
          :rules="{ required: true, message: '请输入语言编码', trigger: 'blur' }"
        >
          <el-input v-model="form.encoding" placeholder="请输入语言编码"></el-input>
        </el-form-item>
        <el-form-item
          label="语言名称"
          prop="name"
          :rules="{ required: true, message: '请输入语言名称', trigger: 'blur' }"
        >
          <el-input v-model="form.name" placeholder="请输入语言名称"></el-input>
        </el-form-item>
        <el-form-item
          label="APP语言包"
          prop="appPackage"
          :rules="{ required: true, message: '请输入APP语言包', trigger: 'blur' }"
        >
          <el-input v-model="form.appPackage" type="textarea" :rows="4" placeholder="请输入APP语言包"></el-input>
          <span>换行请按回车键enter</span>
        </el-form-item>
        <el-form-item label="语言LOGO">
          <el-dropdown placement="top">
            <div>
              <div class="avatar-uploader-logo">
                <i class="el-icon-plus avatar-uploader-logo-icon" v-if="!form.logo"></i>
                <el-image
                  v-else
                  :src="fileUrl+form.logo"
                  style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);cursor: pointer;"
                  fit="contain"
                ></el-image>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="dialogAddFileUpload=true"
                style="padding:0"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
              <el-dropdown-item style="padding:0">
                <el-upload
                  style="text-align:center"
                  :action="actionUrl"
                  accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                  :headers="header"
                  :data="{type:0,category:'图标'}"
                  :show-file-list="false"
                  :on-success="handleAddSuccess"
                  :before-upload="beforeAddUpload"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddLanguage=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddLanguage">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加窗口 图片资源库 -->
    <el-dialog :visible.sync="dialogAddFileUpload" :close-on-click-modal="false" width="55%">
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图标'" v-if="dialogAddFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogAddFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitAddLanguageLogo" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改语言 -->
    <el-dialog :visible.sync="dialogEditLanguage" :close-on-click-modal="false" width="45%">
      <span slot="title">编辑语言</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="120px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="语言编码"
          prop="encoding"
          :rules="{ required: true, message: '请输入语言编码', trigger: 'change' }"
        >
          <el-select v-model="editForm.encoding" style="width:100%">
            <el-option
              v-for="item in languageOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="语言名称"
          prop="name"
          :rules="{ required: true, message: '请输入语言名称', trigger: 'blur' }"
        >
          <el-input v-model="editForm.name" placeholder="请输入语言名称"></el-input>
        </el-form-item>
        <el-form-item label="APP语言包">
          <div class="down-tree">
            <el-form :model="languagePackge" ref="languagePackge" label-width="200px" size="small">
              <el-form-item label="a_key_to_clean_up">
                <el-input v-model="languagePackge.a_key_to_clean_up"></el-input>
              </el-form-item>
              <el-form-item label="app_market_up_tips">
                <el-input v-model="languagePackge.app_market_up_tips"></el-input>
              </el-form-item>

              <el-form-item label="check_for_updates">
                <el-input v-model="languagePackge.check_for_updates"></el-input>
              </el-form-item>
              <el-form-item label="common_application">
                <el-input v-model="languagePackge.common_application"></el-input>
              </el-form-item>
              <el-form-item label="download_fail">
                <el-input v-model="languagePackge.download_fail"></el-input>
              </el-form-item>
              <el-form-item label="downloading">
                <el-input v-model="languagePackge.downloading"></el-input>
              </el-form-item>

              <el-form-item label="is_downloading_please_do_not_click_again">
                <el-input v-model="languagePackge.is_downloading_please_do_not_click_again"></el-input>
              </el-form-item>
              <el-form-item label="launch_time_out">
                <el-input v-model="languagePackge.launch_time_out"></el-input>
              </el-form-item>

              <el-form-item label="resource_fail">
                <el-input v-model="languagePackge.resource_fail"></el-input>
              </el-form-item>
              <el-form-item label="resource_loading">
                <el-input v-model="languagePackge.resource_loading"></el-input>
              </el-form-item>

              <el-form-item label="monday">
                <el-input v-model="languagePackge.monday"></el-input>
              </el-form-item>
              <el-form-item label="tuesday">
                <el-input v-model="languagePackge.tuesday"></el-input>
              </el-form-item>
              <el-form-item label="wednesday">
                <el-input v-model="languagePackge.wednesday"></el-input>
              </el-form-item>
              <el-form-item label="thursday">
                <el-input v-model="languagePackge.thursday"></el-input>
              </el-form-item>
              <el-form-item label="friday">
                <el-input v-model="languagePackge.friday"></el-input>
              </el-form-item>
              <el-form-item label="saturday">
                <el-input v-model="languagePackge.saturday"></el-input>
              </el-form-item>
              <el-form-item label="sunday">
                <el-input v-model="languagePackge.sunday"></el-input>
              </el-form-item>

              <el-form-item label="simple_main_down_tips">
                <el-input v-model="languagePackge.simple_main_down_tips"></el-input>
              </el-form-item>

              <el-form-item label="system_settings">
                <el-input v-model="languagePackge.system_settings"></el-input>
              </el-form-item>

              <el-form-item label="today">
                <el-input v-model="languagePackge.today"></el-input>
              </el-form-item>
              <el-form-item label="tomorrow">
                <el-input v-model="languagePackge.tomorrow"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-form-item>
        <el-form-item label="语言LOGO">
          <el-dropdown placement="top">
            <div>
              <div class="avatar-uploader-logo">
                <i class="el-icon-plus avatar-uploader-logo-icon" v-if="!editForm.logo"></i>
                <el-image
                  v-else
                  :src="fileUrl+editForm.logo"
                  style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);cursor: pointer;"
                  fit="contain"
                ></el-image>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="dialogEditFileUpload=true"
                style="padding:0"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
              <el-dropdown-item style="padding:0">
                <el-upload
                  style="text-align:center"
                  :action="actionUrl"
                  accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                  :headers="header"
                  :data="{type:0,category:'图标'}"
                  :show-file-list="false"
                  :on-success="handleEditSuccess"
                  :before-upload="beforeEditUpload"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditLanguage=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditLanguage">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加窗口 图片资源库 -->
    <el-dialog
      :visible.sync="dialogEditFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图标'" v-if="dialogEditFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditLanguageLogo" size="small">确11 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getLanguageApi,
  addLanguageApi,
  editLanguageApi,
  delLanguageApi,
  defaultLanguageApi
} from "../../api/data";
import FileUpload from "../../components/FileUpload";
import Aes from "../../utils/aes";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      loading: true,
      list: [],
      form: {
        encoding: "",
        logo: "",
        name: "",
        appPackage: ""
      },
      editForm: {},
      languageOptions: [
        { value: "cht", label: "繁体中文" },
        { value: "en", label: "英语" },
        { value: "jp", label: "日语" },
        { value: "kor", label: "韩语" },
        { value: "fra", label: "法语" },
        { value: "de", label: "德语" },
        { value: "ru", label: "俄语" },
        { value: "el", label: "希腊语" },
        { value: "nl", label: "荷兰语" },
        { value: "spa", label: "西班牙语" },
        { value: "pt", label: "葡萄牙语" },
        { value: "it", label: "意大利语" },
        { value: "ara", label: "阿拉伯语" }
      ],
      dialogAddLanguage: false, //添加语言窗口
      dialogAddFileUpload: false, //添加语言窗口图片资源库
      dialogEditLanguage: false, //修改语言窗口
      dialogEditFileUpload: false, //修改语言窗口 图片资源库
      languagePackge: {}
    };
  },
  computed: {
    //当前场所信息
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  created() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      getLanguageApi({ groupId: this.placesInfo.groupId }).then(res => {
        if (res) this.list = res.data;
        this.loading = false;
      });
    },
    //添加语言(废弃)
    onSubmitAddLanguage() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.groupId = this.placesInfo.groupId;
          addLanguageApi(this.form).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.dialogAddLanguage = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加窗口关闭事件(废弃)
    closeDialogAddLanguage() {
      this.$refs["form"].resetFields();
    },
    //添加语言窗口 图片资源库(废弃)
    onSubmitAddLanguageLogo() {
      this.dialogAddFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.form.logo = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //上传完成事件(废弃)
    handleAddSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.logo = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传前事件(废弃)
    beforeAddUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //恢复默认
    defaultLanguage(row) {
      defaultLanguageApi({ preset: row.preset, id: row.id }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //删除语言(废弃)
    onSubmitDelLanguage(row) {
      this.$confirm("此操作将永久删除该语言, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delLanguageApi({ id: row.id }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initGroup();
          }
        });
      });
    },
    //修改排序
    onChangeSort(row) {
      editLanguageApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //修改排序
    onSwitchState(row) {
      editLanguageApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //提交编辑
    onSubmitEditLanguage() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          this.editForm.appPackage = JSON.stringify(this.languagePackge);
          editLanguageApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.dialogEditLanguage = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //打开编辑窗口
    openEditLanguage(row) {
      this.editForm = { ...row };
      this.languagePackge = eval("(" + row.appPackage + ")");
      this.dialogEditLanguage = true;
    },

    //图片窗口关闭事件
    closeDialogEditFileUpload() {
      this.$store.commit("setFileList", []);
    },
    //添加语言窗口 图片资源库
    onSubmitEditLanguageLogo() {
      this.dialogEditFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.editForm.logo = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //上传完成事件
    handleEditSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.editForm.logo = result.msg;
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeEditUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    }
  }
};
</script>
<style lang='scss'>
.avatar-uploader-logo {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader-logo:hover {
  border-color: #5e72f9;
}
.avatar-uploader-logo-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.down-tree {
  height: 200px;
  display: block;
  overflow-y: scroll;
  border: 1px solid #d7dae2;
  border-radius: 4px;
}
</style>